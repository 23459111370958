import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import JobCardNew from "../../components/common/JobCardNew"
import JobCats from "../../components/common/JobCats"

const JobsPage = () => {
  const data = useStaticQuery(graphql`
    query JobsPageQuery {
      allPrismicJobPost(
        sort: {
          order: [DESC, DESC],
          fields: [data___featured, first_publication_date]
        }
      ) {
        edges {
          node {
            id
            uid
            data {
              job_title
              job_type
              remote_location
              maximum_annual_salary
              minimum_annual_salary
              featured
              company {
                document {
                  ... on PrismicCompany {
                    id
                    data {
                      company_name
                      logo {
                        alt
                        gatsbyImageData(
                          width: 80
                        )
                      }
                    }
                  }
                }
              }
              keywords {
                keyword
              }
            }
            first_publication_date(formatString: "DD MMM YYYY")
          }
        }
      }
    }
  `)

  const jobs = data.allPrismicJobPost.edges

  return (
    <Layout>
      <Seo
        title="Remote crypto, blockchain and web3 developer jobs"
      />

      <div className="max-w-7xl mx-auto px-4 mt-6 mb-24">
        <div>
          <h1 className="text-gray-300 text-xl md:text-2xl tracking-wider font-semibold">Remote Blockchain Developer Jobs Added Recently</h1>
        </div>

        <div className="mt-6">
          <div className="mt-4">
            <JobCats />

            <div className="my-16">
              <h2 className="text-gray-200 text-5xl lg:text-7xl font-black">All Jobs</h2>
            </div>

            <div>
              {jobs.map(item => {
                const compLogo = getImage(item.node.data.company.document.data.logo)

                return (
                  <JobCardNew
                    jobId={item.node.id}
                    jobLink={item.node.uid}
                    jobTitle={item.node.data.job_title}
                    compLogo={compLogo}
                    compLogoAlt={item.node.data.company.document.data.logo.alt}
                    compName={item.node.data.company.document.data.company_name}
                    jobType={item.node.data.job_type}
                    remoteLocation={item.node.data.remote_location}
                    minSalary={item.node.data.minimum_annual_salary}
                    maxSalary={item.node.data.maximum_annual_salary}
                    jobPostDate={item.node.first_publication_date}
                    featured={item.node.data.featured ? true : false}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JobsPage
